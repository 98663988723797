import { useTranslation } from 'react-i18next';

import { isAttachmentComplete } from '../attachment';
import { POINT_CLOUD_TYPE, POINT_CLOUD_BUDGET_LABEL, POINT_CLOUD_BUDGET_VALUE } from '../../constants';
import { metersToMillimeters } from '../math';
import { capitalize } from '../string';

import IconCombination from '../../assets/svg/IconCombination';
import IconExtractor from '../../assets/svg/IconExtractor';
import IconUpload from '../../assets/svg/IconUpload';
import IconImage2D from '../../assets/svg/IconImage2D';

export const getPointCloudIcon = (pointCloud) => {
  switch (pointCloud.type) {
    case POINT_CLOUD_TYPE.COMBINED:
      return IconCombination;
    case POINT_CLOUD_TYPE.EXTRACTION:
      return IconExtractor;
    case POINT_CLOUD_TYPE.GEOTIFF:
      return IconImage2D;
    default:
      return IconUpload;
  }
};

export const isPointCloudCombination = (pointCloud) => pointCloud.type === POINT_CLOUD_TYPE.COMBINED;

export const isPointCloudExtraction = (pointCloud) => pointCloud.type === POINT_CLOUD_TYPE.EXTRACTION;

export const isPointCloudStatusComplete = (pointCloud) => isAttachmentComplete(pointCloud.attachment);

export const isPointCloudUpload = (pointCloud) => pointCloud.type === POINT_CLOUD_TYPE.RAW;

export function MinPointSpacingString(props) {
  const { t } = useTranslation();

  const spacing = metersToMillimeters(props.spacing);

  return !spacing
    ? capitalize(t('words.none'))
    : spacing.toFixed(1) + t('units.mm');
}

export const getPointCloudOptions = () => {
  const pointBudgetLabel = [POINT_CLOUD_BUDGET_LABEL.TWENTY, POINT_CLOUD_BUDGET_LABEL.FIFTY, POINT_CLOUD_BUDGET_LABEL.HUNDRED, POINT_CLOUD_BUDGET_LABEL.TWOHUNDRED];

  const pointBudgetValue = [POINT_CLOUD_BUDGET_VALUE.TWENTY, POINT_CLOUD_BUDGET_VALUE.FIFTY, POINT_CLOUD_BUDGET_VALUE.HUNDRED, POINT_CLOUD_BUDGET_VALUE.TWOHUNDRED];

  const answer = pointBudgetLabel.map((x, i) => ({ label: x, value: pointBudgetValue[i] }));

  return answer;
};

export * from './classification';
export * from './viewer';
